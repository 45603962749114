.bold {
    font-weight: bold;
}
.feedback-popup {
    padding: 16px;
    width: 600px;
    height: 350px;
}
.feedback-content {
    padding-top: 30px;
}
.provider-picture img {
    border-radius: 20px;
}

.feedback-header {
    display: flex;
    padding-top: 14px;
}

.provider-data {
    margin-left: 10px;
    opacity: 0.6;
}
.smiley-icon {
    cursor: pointer;
    margin-right: 8px;
}
.smiley-icon:hover svg, .smiley-icon.selected svg {
    background: rgba(49, 123, 140, 0.1);
    border-radius: 20px;
}

.smiley-icon:hover svg path, .smiley-icon.selected svg path {
    fill: #317B8C;
}
.tags {
    margin-top: 40px;
}
.feedback-tag {
    color: #317B8C;
    border: 1px solid #317B8C;
    border-radius: 20px;
    padding: 5px 10px;
    cursor: pointer;
    margin-right: 10px;
}
.feedback-tag:hover {
    background: rgba(49, 123, 140, 0.1);
}
.feedback-tag.selected {
    background: #317B8C;
    color: white;
}
.note-area {
    width: 460px;
    height: 74px;
    margin-top: 20px;
}
.finish-content {
    display: flex;
}

.finish-content .text {
    width: 316px;
}
.states-smiley {
    margin-top: 40px;
}