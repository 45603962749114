#app {
  max-width: 850px;
  margin: 0 auto;
}

nav {
  padding: 20px 0 20px 0;
}
[class^="arrow-"] {
  width: 7px;
  height: 7px;
  margin-left: 5px;
  border-top: 1px solid #54585d;
  border-left: 1px solid #54585d;
  margin-bottom: 4px;
  display: inline-block;
  cursor: pointer;
}
[class^="arrow-"].disabled {
  opacity: 0.5;
  cursor: default;
}
.arrow-down {
  transform: rotate(225deg);
}
