.participants-list {
  overflow: auto;
  max-height: 310px;
  min-height: 200px;
}
.participant-title {
  margin-bottom: 8px;
  color: rgba(84, 88, 93, 0.6);
}
.participants-list-container {
  padding: 17px;
  box-sizing: border-box;
  align-self: stretch;
}
.participant-element {
  display: grid;
  grid-template-columns: 50px auto 60px;
  align-items: center;
  margin-bottom: 12px;
}
.participant-icon img {
  border-radius: 30px;
}

.participant-call-icon {
  position: absolute;
  cursor: pointer;
  top: 50%;
  transform: translateY(-50%);
}
.participant-call-icon.mail-icon {
  right: 35px;
}

.participant-call-icons {
  position: relative;
}

.participant-call-icon.phone-icon {
  right: 5px;
}
.participant-name .message {
  position: absolute;
  color: rgba(84, 88, 93, 0.6);
  white-space: nowrap;
}
.participant-name {
  position: relative;
}
