#app {
  max-width: none !important;
}

.main-page {
  background: url("../images/session.jpg") no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: 100vh;
}
#logo-container {
  position: absolute;
  top: 50px;
  left: 50px;
  z-index: 1;
}
.bottom-logo {
  top: 160px !important;
}
#page-not-found {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="text"],
input[type="number"],
select {
  padding-left: 12px;
  border: 1px solid #dadcdf;
  background-color: #ffffff;
  height: 32px;
  box-sizing: border-box;
}
input[type="text"]:disabled,
input[type="number"]:disabled {
  color: #54585d;
}

.login-container {
  display: grid;
  row-gap: 13px;
}
.error-message {
  color: #d24667;
  font-size: 12px;
}

.OT_bar.OT_edge-bar-item.OT_mode-auto {
  display: none;
}

.OT_video-poster {
  opacity: 1;
  border-radius: 60px;
  width: 120px;
  height: 120px;
  background-size: cover !important;
}

.OT_widget-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #292929;
}
