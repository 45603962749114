.video-button-container {
  cursor: pointer;
  display: grid;
  justify-items: center;
}
.video-button {
  height: 40px;
  width: 40px;
  display: grid;
  align-items: center;
  justify-content: center;
  border-radius: 30px;
}

.video-button-text {
  margin-top: 4px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 19px;
  text-align: center;
  color: #F8F9F9;
  opacity: 0.6;
  font-size: 11px;
}
.wrs-container .video-button, .publisher-buttons-left .video-button {
  background-color: #e7ebed;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.5);
}