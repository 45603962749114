.button-new {
  padding: 0px 12px;
  height: 32px;
  min-width: 65px;
  border-radius: 3px;
  font-size: 13px;
  line-height: 32px;
  cursor: pointer;
  text-align: center;
  box-sizing: border-box;
}

.button-new.primary-button {
  color: #ffffff;
  background-color: #317b8c;
}
.button-new.primary-button:hover {
  background-color: #317b8c;
}
.button-new.primary-button.disable {
  background-color: #b9c2cb;
  cursor: default;
  pointer-events: none;
}

.button-new.secondary-button {
  color: #317b8c;
  background-color: #ffffff;
  border: 1px solid#317B8C;
}
.button-new.secondary-button:hover {
  background-color: #f9f9fa;;
}
.button-new.secondary-button.disable {
  border: 1px solid #b9c2cb;
  background-color: #ffffff;
  color: #b9c2cb;
  cursor: default;
}

.button-new.link-button {
  color: #4285f4;
  background-color: inherit;
}
.button-new.link-button:hover {
  background-color: #f9f9fa;;
}
.button-new.link-button.disable {
  color: #b9c2cb;
  cursor: default;
  background-color: inherit;
}

.button_simulate_loader {
  width: 110px;
  height: 40px;
  position: relative;
  margin: auto;
}
.button_simulate_loader .loader {
  position: relative;
  margin: auto;
  border: 16px solid #3498db;
  border-radius: 50%;
  border-top: 16px solid #f3f3f3;
  width: 10px;
  height: 10px;
  -webkit-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
}
