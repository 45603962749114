.small-popup {
  width: 356px;
}

.popup-header {
  display: grid;
  grid-template-columns: 80% 20%;
  align-items: center;
  display: grid;
  padding: 16px;
  border-bottom: 1px solid #dadcdf;
}
.popup-header .popup-header-content {
  font-size: 16px;
}
.popup-body {
  padding: 16px;
}
.popup-footer {
  padding: 16px;
  border-top: 1px solid #dadcdf;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.popup-footer .button-new {
  margin-left: 5px;
}
.popup-header-icon {
  justify-self: end;
}

.icon-button-component {
  cursor: pointer;
  display: grid;
}
