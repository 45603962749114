#video-chat {
  z-index: 1;
  width: 360px;
  background: #fff;
  display: grid;
  grid-template-rows: 60px auto 100px;
  max-height: 100vh;
}
#chat-footer {
  padding: 15px;
  justify-self: center;
  align-self: end;
  width: 100%;
}
#chat-header {
  text-align: center;
  padding: 15px;
}

#chat-header .icon-button-component {
  justify-self: start;
}
.chat-title {
  display: inline-block;
  font-size: 30px;
}
#chat-header .icon-button-component {
  position: absolute;
}

.message {
  display: grid;
  grid-template-columns: 40px auto;
}
.message-content {
  direction: ltr;
  border-radius: 10px;
  color: #54585d;
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 19px;
  background-color: #f7f7f7;
  justify-self: start;
  padding: 10px;
  word-break: break-word;
}
#chat-body {
  max-height: 100%;
  overflow: auto;
  align-self: end;
}
.message-container {
  padding: 0 15px;
}
.message-container:not(:first-child) {
  margin-top: 20px;
}
.no-date-message {
  margin-top: 5px !important;
}
.message-container {
  padding: 0 15px;
}
.message-container .message-icon {
  align-self: start;
}
.message-icon img {
  border-radius: 30px;
}
.message-date-and-content {
  display: grid;
}
.message-date {
  color: #abadaf;
  font-size: 11px;
}

.unread-message,
.unread-message span {
  color: #e74243;
  border-color: #e74243;
}

.chat-date,
.chat-date span {
  color: #abadaf;
  border-color: #abadaf;
}

.text-between-lines {
  font-size: 11px;
  width: 100%;
  line-height: 1;
  text-align: center;
  overflow: hidden;
  margin: 16px 0;
}
.text-between-lines span {
  display: inline-block;
  background: #fff;
  position: relative;
}
.text-between-lines span:after,
.text-between-lines span:before {
  content: "";
  position: absolute;
  height: 5px;
  border-bottom: 0.5px solid;
  top: 5px;
  width: 180%;
}

.text-between-lines span::before {
  right: 100%;
  margin-right: 15px;
}

.text-between-lines span::after {
  left: 100%;
  margin-left: 15px;
}
#message-dest select {
  border: none;
  font-weight: bold;
}

#message-dest {
  font-size: 14px;
}

#send-message input {
  width: 100%;
  border: none;
  border-radius: 10px;
  border: 1px solid rgba(100, 121, 143, 0.1);
  background-color: rgba(242, 245, 245, 0.8);
  height: 36px;
}

#send-message svg {
  position: absolute;
  right: 10px;
  top: 4px;
}

#send-message {
  position: relative;
}
.own-message {
  text-align: right;
}

.own-message .message {
  display: block;
  direction: rtl;
}
.own-message .message-content {
  margin-left: 40px;
}

.patient-message .message-content {
  background: #4fcec8;
  color: #FFFFFF;
}
.send-button {
  cursor: pointer;
}
